import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component } from 'vue-property-decorator'
import GtrModuleLayout from '@/modules/common/views/layouts/level-two/module/module.layout.vue'
import { Module } from '@/modules/common/enums/modules.enum'

@Component({
  name: 'GtrHotelsModuleIndexView'
})
export default class GtrHotelsModuleIndexView extends GtrSuper {
  created () {
    this.$emit('update:layout', GtrModuleLayout)
    this.$bus.$emit('update:menu', Module.HOTELS)
  }
}
